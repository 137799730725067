.talent-primary-badge {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  background-color: var(--primary-hover);
  border-radius: 20px;
  letter-spacing: 0.5px;
  padding: 0.275rem 0.475rem;
  text-transform: uppercase;
}

.member-card {
  overflow: hidden;
}