.outline-text-wrapper {
  position: relative;
  &:after, &:before {
    height: 100%;
    width: 100%;
    content: "";
    position: absolute;
    display: block;
  }
  &:after {
    z-index: 2;
    border: 1px solid var(--primary);
    top: -20px;
    left: -20px;
  }
  &:before {
    z-index: 1;
    border: 1px solid var(--primary-hover);
    right: -20px;
    bottom: -20px;
  }
  .outline-text {
    -webkit-text-stroke-width: 1px !important;
    -webkit-text-stroke-color: var(--white) !important;
    color: var(--dark) !important;
    text-transform: uppercase;
  }
}