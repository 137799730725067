.splash-page-body {
  --dark: #000;
  --primary: #000;
  --primary-color: #454545;
  --swiper-theme-color: #000;
  --primary-hover: #454545;
  --white: #fff;
}

.splash-page-body {
  width: 100%;
  width: 100%;
  min-height: 100vh;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(0, 0, 0);
  overflow: hidden;
  &::after {
    background-color: rgb(0, 0, 0, 0.5);
    content: "";
    inset: 0 0 0 0;
    height: 100%;
    min-height: 100vh;
    width: 100%;
    z-index: 1;
    position: absolute;
  }

  .splash-page-section {
    width: 100vw;
    height: 45vh;
    @media only screen and (min-width: 768px)  {
      width: 50vw;
      height: 95vh;
    }
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .splash-page-link {
      img {
        opacity: 0.9;
      }
    }
  }

  .splash-page-link {
    margin: 1rem auto;
    display: block;
    box-shadow: 5px 5px var(--white);
    border: 2px solid var(--white) !important;
    transition: all ease-in 0.3s !important;
    border-radius: 8px;
    transform: scale(1);
    background: linear-gradient(#00000000, #79797900);
    background-position-y: 1000px;
    background-repeat: no-repeat;
    transition-delay: .75ms;
    img {
      opacity: 1;
      -webkit-transition: .3s ease-in-out;
      transition: .3s ease-in-out;
      filter: saturate(1);
      width: 100%;
      margin: 0 auto;
      display: block;
      max-width: 50vw;
      @media only screen and (min-width: 768px)  {
        max-width: 30vw;
      }
      @media only screen and (min-width: 960px)  {
        max-width: 25vw;
      }
      @media only screen and (min-width: 1200px)  {
        max-width: 20vw;
      }
    }
    p {
      margin: 0 !important;
      padding: 0.457rem 0.857rem;
      background: none;
      &:after {
        background: var(--primary);
      }
    }
    &:hover, &:focus {
      background-color: rgba(100, 100, 100, 0.4);
      transform: scale(1.2);
      box-shadow: 0px 0px var(--white);
      transform: translateY(4px) !important;
      transition: all ease-in 0.2s;
      opacity: 1 !important;
      background-position-y: 0px;
      img {
        opacity: 1 !important;
        filter: saturate(1.25);
      }
    }
  }
}

#FMEntertainment a.splash-page-link {
  &:hover, &:focus {
    background: linear-gradient(#00000030, #a0a0a018);
    border-color: #fff !important;
    box-shadow: 0px 0px #fff;
  }
}

#FMMusic a.splash-page-link {
  &:hover, &:focus {
    background: linear-gradient(#00000030, #a0a0a018);
    border-color: #fff !important;
    box-shadow: 0px 0px #fff;
  }
}

.splash-page-link {
  overflow: hidden;
  position: relative;
}

.splash-page-link::before {
	position: absolute;
	top: 0;
	left: -100%;
	z-index: 2;
	display: block;
	content: '';
	width: 50%;
	height: 100%;
	background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
	-webkit-transform: skewX(-25deg);
	transform: skewX(-25deg);
}
.splash-page-link:hover::before {
	-webkit-animation: shine .75s;
	animation: shine .75s;
}
@-webkit-keyframes shine {
	100% {
		left: 125%;
	}
}
@keyframes shine {
	100% {
		left: 125%;
	}
}
