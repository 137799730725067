.video-wrapper {
  overflow: hidden;
  min-height: 70vh;
  max-height: 85vh;
  height: 85vh;
  position: relative;

  .video-overlay-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 4rem 2rem;
    margin: auto;
    text-align: center;
    z-index: 3;
    position: absolute;
    &.align-bottom {
      @media screen and (max-width: 960px) {
        justify-content: flex-end;
        text-align: center;
      }
    }
    &.bottom-left {
      @media screen and (max-width: 960px) {
        margin: auto auto 2rem 4rem;
        align-items: flex-end;
        justify-content: flex-end;
        text-align: left;
      }
    }
    .title-text {
      font-size: 2.5rem;
      @media screen and (min-width: 960px) {
        font-size: 3.5rem;
      }
      text-shadow: 0em 0em 0.3em rgb(0 0 0 / 40%)
    }
  }
  .tagline-wrapper {
    @media screen and (max-width: 960px) {
      display: none;
    }
    position: absolute;
    bottom: 0px;
    right: 0;
    padding: 1rem 3rem;
    color: black;
    min-width: 320px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    .footer-social-icons {
      a {
        text-decoration: none !important;
        cursor: pointer;
        font-size: 2rem;
        margin-right:0.5rem;
        border-bottom: none;
        color: var(--white);
        transition: all 200ms ease;
        &:hover, &:focus-within {
          border-bottom: none;
          color: var(--primary-hover) !important;
        }
      }
    }
  }
  .background-image-only {
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -1;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 45vh;
    width: 100%;
    height: 100%;
    @media screen and (min-width: 960px) {
      // background-attachment: fixed;
      min-height: 55vh;
    }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   width: 100%;
    //   height: 100%;
    //   background: var(--maskbg);
    //   z-index: 0;
    // }
  }
  .main {
    width: 100%;
    height: 100%;
  }
  
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.4);
  }
}

.black-theme {
  .video-wrapper {
    filter: grayscale(1);
    &:after {
      position: absolute;
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba(0,0,0,0.25);
    }
  }
}

.red-theme.chuymontana {
  .video-wrapper {
    filter: grayscale(0.5);
    &:after {
      position: absolute;
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba(255, 0, 0, 0.322);
    }
  }
}

.forest-theme {
  .video-wrapper {
    &:after {
      position: absolute;
      inset: 0 0 0 0;
      width: 100%;
      height: 100%;
      content: '';
      background: rgba(1, 40, 24, 0.25);
    }
  }
}